'use client'

export default function GoogleIniScript() {
    return (
        <>
            <script async
                src={`https://maps.googleapis.com/maps/api/js?key=AIzaSyCl2fza69cDI2mn9OR0QzOYp4tslF81Svs&loading=async&libraries=places&callback=initMap`}>
            </script>
            <script async
                src="/scripts/google.js">
            </script >
        </>
    );
}